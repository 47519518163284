<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">平台软件定制开发</h1>
        <p data-caption-delay="100" class="wow fadeInRight">提供稳定高效的测试管理、场景分析、二次开发、数据交换平台及其他各类定制化软件服务与解决方案</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
          <div>立即咨询</div>
        </div>
      </div>
    </div>
    <div class="sw-tab-title" ref="tab" :class="tabFixed ? getTabFixedClass() : ''">
      <div class="container">
        <el-tabs v-model="activeProduct" @tab-click="handleTabClick">
          <el-tab-pane label="StormETM电子电气集成测试管理系统" name="1">StormETM电子电气集成测试管理系统</el-tab-pane>
          <el-tab-pane label="StormScena场景管理分析系统" name="2">StormScena场景管理分析系统</el-tab-pane>
          <el-tab-pane label="定制化软件开发服务" name="3">定制化软件开发服务</el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div :class="tabFixed?'solution-tab-fixed':''">
      <div v-if="activeProduct === '1'">
        <section class="solution-intros platform-soft-solution-intro wow fadeInLeft">
          <div class="container">
            <div class="normal-title">产品介绍</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <p class="intro-detail">
                    StormETM是一款面向电子电气集成测试的过程管理软件。
                  </p>
                  <p class="intro-detail">
                    产品可构建统一的功能库与用例库，支持以项目为单位进行计划管理、样件管理、过程管理、统计分析，实现整车/系统/部件电气测试工作的信息化、标准化，提升整车电气集成测试工作的精细化管理水平。
                  </p>
                  <div class="intro-picture">
                    <el-row :gutter="30">
                      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                        <img src="../../../assets/images/etm-01.png" alt="">
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                        <img src="../../../assets/images/etm-02.png" alt="">
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <div class="all-function">
              <ul class="priority-list">
                <li><span>基础功能库管理</span>建立整车电气功能信息库与测试用例信息库，为整车电气测试提供标准化输入，形成有效的技术储备及知识积累</li>
                <li><span>测试项目管理</span>以项目为管理维度，实现各测试功能、用例及相关样件、控制器的信息化管理</li>
                <li><span>测试过程管理</span>修订测试计划，记录测试执行过程情况，当发现问题时，及时反馈并维护问题状态</li>
                <li><span>统计分析</span>实现集成测试过程中各类数据统计管理，避免由于人工操作所造成的数据准确性及时性差等问题</li>
                <li><span>基础信息管理</span>统一管理组织、人员、角色、权限、公告、邮件、流程、日志等基础信息</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '2'" class="tab-container">
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <div class="normal-title">产品介绍</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <p class="intro-detail">
                    StormScena是一款用于整车架构需求设计开发时进行场景管理并辅助分析的业务系统。产品提供基础场景库，并可在此基础上，支持用户有效整理现实中大量的单一描述数据作为元素，通过人、车、环境的有机融合，批量组合出相关场景，并按照一定的规则设置元素间的关系（依赖、互斥等），自动生成现实中复杂的业务场景，引导客户分析用户痛点，实现发掘并创新车辆功能列表的目的。
                  </p>
                  <img src="../../../assets/images/scena-01.png" style="display: block;width: 70%;margin: 20px auto;"
                       alt="">
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <div class="all-function">
              <ul class="priority-list">
                <li>提供基础场景库，提供典型场景中的维度与元素；</li>
                <li>支持场景扩充与泛化，支持灵活维护场景中的维度/元素；</li>
                <li>支持定义维度、元素之间的依赖互斥关系，避免生成无效场景；</li>
                <li>支持引导工程师从场景出发，梳理用户痛点、分析功能特性、积累场景分析成果物；</li>
                <li>支持以工程为单位进行场景分析工作，支持多项目并行分析；</li>
                <li>支持元素自定义编目与优先级管理；</li>
                <li>支持图形化显示场景结构图，直观展示场景中元素、维度的关系；</li>
                <li>支持附加场景图片、视频等描述信息；</li>
                <li>支持自然语言的场景描述智能分词、匹配元素数据；</li>
                <li>支持AI绘图，通过场景描述自动生成场景简图；</li>
                <li>支持全局搜索、快速定位场景/维度/元素信息；</li>
                <li>支持多部门、多用户进行协同场景分析管理工作。</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '3'" class="tab-container">
        <section class="solution-intros wow fadeInLeft">
          <div class="container">
            <div class="normal-title">服务介绍</div>
            <div class="all-intro">
              <el-row>
                <el-col :span="24">
                  <p class="intro-detail">
                    面向客户实际应用中的各类业务需求，东信创智平台软件部可提供定制化软件开发服务与解决方案，包括桌面工具、管理系统、数据分析、互联互通等，协助客户降低操作难度、提升工作效率、规范操作流程、精细化业务管理。
                  </p>
                </el-col>
              </el-row>
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInRight">
          <div class="container">
            <div class="normal-title">统计分析软件</div>
            <div class="solutions-intro-container">
              <p class="tab-inner-intro">面向车辆研发/生产/售后各阶段产生的业务数据，提供数据监控分析系统，辅助业务人员进行问题分析与管理。</p>
              <h3>PREEvision工具使用情况监控分析</h3>
              <p class="tab-inner-intro">提供统计使用/闲置情况、构建创建情况等内容进行统计分析与监控，为企业掌握工具利用情况、评估业务量、人员配比合理性、后续工具扩容/升级工作提供基础。</p>
              <img src="../../../assets/images/solutions/platform-sw-04.png"
                   :style="isMobile ? 'width: 100%;margin: 0px auto;' : 'display: block;width: 70%;margin: 20px auto;'" alt="">
              <h3>车辆运行异常情况监控分析</h3>
              <p class="tab-inner-intro">针对车辆运行过程中采集的报文信息进行异常分析，快速定位异常情况，为测试人员提供参考依据，简化测试难度，提升工作效率。</p>
              <img src="../../../assets/images/solutions/platform-sw-05.png"
                   :style="isMobile ? 'width: 100%;margin: 0px auto;' : 'display: block;width: 70%;margin: 20px auto;'" alt="">
              <h3>互联互通平台</h3>
              <p class="tab-inner-intro">打通各系统间各自独立的信息孤岛，构建互联互通的业务流程通路，实现统一管理、统一检索、统一分析，规范业务流程，为业务复用、管理决策提供技术支撑。</p>
              <img src="../../../assets/images/solutions/platform-sw-06.png"
                   :style="isMobile ? 'width: 100%;margin: 0px auto;' : 'display: block;width: 70%;margin: 20px auto;'" alt="">
            </div>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInRight">
          <div class="container">
            <div class="normal-title">优势亮点</div>
            <div class="all-function">
              <ul class="priority-list">
                <li>深厚的汽车电子行业经验积淀，深度理解汽车电子行业标准、协议及实践方法论</li>
                <li>丰富的IT软件行业技能积累，全面掌握软件系统开发各阶段技术、工具</li>
                <li>以客户需求为出发点，为客户量身定制个性化软件解决方案</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>


    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from "@/components/MainNav.vue";
import mainFooter from "@/components/MainFooter.vue";
import bzInfo from "@/components/BzInfo.vue";

export default {
  name: "platformSoftware",
  components: {
    mainFooter,
    mainNav,
    bzInfo
  },
  data() {
    return {
      tabFixed: false,
      activeIndex: '2-7',
      activeProduct: '1',
      chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
      isMobile: false,
    }
  },
  mounted() {
    document.title = "平台软件定制开发 - 解决方案 - 东信创智"
    new this.$wow.WOW().init()
    window.addEventListener("scroll", this.handleScroll);
    this.isMobile = document.documentElement.clientWidth < 1200
    window.onresize = () => {
      return (() => {
        this.isMobile = document.documentElement.clientWidth < 1200;
      })();
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    linkUrl(url) {
      window.open(url, '_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
    handleScroll() {
      if (document.documentElement.clientWidth > 1100) {
        let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 70
        if (top < 0 && top > -630) {
          this.tabFixed = !this.tabFixed;
        } else if (document.documentElement.scrollTop > 630) {
          this.tabFixed = true;
        }
      } else {
        let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 50
        if (top < 0 && top > -250) {
          this.tabFixed = !this.tabFixed;
        } else if (document.documentElement.scrollTop > 250) {
          this.tabFixed = true;
        }
      }
    },
    getTabFixedClass() {
      if (document.documentElement.clientWidth < 1100) {
        return 'sw-tab-title-mobile-fixed'
      } else {
        return 'sw-tab-title-fixed'
      }
    },
    handleTabClick() {
      if (document.documentElement.clientWidth > 1100 && document.documentElement.scrollTop > 630) {
        document.documentElement.scrollTop = 630
      } else if (document.documentElement.clientWidth < 1100 && document.documentElement.scrollTop > 250) {
        document.documentElement.scrollTop = 250
      }
    },
  }
}
</script>

